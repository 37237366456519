import { cn } from '@collection-platform-frontend/shared';
import {
  BaseLayout,
  Typography,
  WalletUser,
} from '@collection-platform-frontend/ui';
import {
  ArrowTopRightOnSquareIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { AuthUser } from 'next-firebase-auth';
import { FC, PropsWithChildren, useCallback } from 'react';

import { useRouter } from '../../libs/router';
import { Icons } from '../icons';
import { Header } from './header';

const footerLinks = [
  {
    label: 'はじめての方へ',
    href: '/beginners',
  },
  {
    label: 'よくある質問',
    href: 'https://support.cldr.jp/hc/ja',
  },
  {
    label: '運営会社',
    href: 'https://www.shogakukan.co.jp',
  },
  {
    label: '利用規約・プライバシーポリシー',
    href: '/policy',
  },
  {
    label: '特定商法に基づく表記',
    href: '/policy/law',
  },
  {
    label: 'お問い合わせ',
    href: 'https://cloudear.zendesk.com/hc/ja/requests/new',
  },
];

type Props = PropsWithChildren<{
  authUser?: AuthUser;
  user?: WalletUser;
  className?: string;
}>;

export const PageLayout: FC<Props> = ({
  authUser,
  user,
  className,
  children,
}) => {
  const { push } = useRouter();

  const onLink = useCallback(
    (value: string) => {
      push(value);
    },
    [push],
  );

  const onLogin = useCallback(() => {
    push('/auth');
  }, [push]);

  const onLogout = useCallback(() => {
    authUser.signOut();
  }, [authUser]);

  const onBackToHome = useCallback(() => {
    push('/');
  }, [push]);

  return (
    <BaseLayout
      headerContent={
        <Header
          authUser={authUser}
          user={user}
          onBack={onBackToHome}
          onLink={onLink}
          onLogin={onLogin}
          onLogout={onLogout}
        />
      }
      mainContent={
        <div
          className={cn(
            className,
            'relative flex flex-col items-center w-full font-noto mt-[78px]',
          )}
        >
          <div className="flex w-full max-w-7xl">{children}</div>
        </div>
      }
      footerContent={
        <footer className="flex items-center justify-center border-t text-cloudear-blue bg-cloudear-base border-cloudear-border rounded-2xl">
          <div className="flex flex-col w-full p-6 space-y-10 max-w-7xl ">
            <Icons.CloudearLogo
              className="cursor-pointer"
              onClick={onBackToHome}
            />
            <div className="flex flex-col flex-wrap w-full space-y-4 sm:items-center sm:flex-row sm:justify-between sm:space-y-0 md:space-x-6">
              {footerLinks.map(({ label, href }, i) => {
                return (
                  <LinkButton
                    key={i}
                    className="w-full sm:w-[48%] !m-0 lg:w-auto justify-between md:justify-start py-4"
                    href={href}
                  >
                    {label}
                  </LinkButton>
                );
              })}
            </div>
            <div className="flex items-center justify-center">
              <Typography variant="body2">
                © Shogakukan Inc. 2022 All rights reserved.
              </Typography>
            </div>
          </div>
        </footer>
      }
    />
  );
};

type LinkButtonProps = PropsWithChildren<{
  className?: string;
  href?: string;
}>;

export const LinkButton: FC<LinkButtonProps> = ({
  className,
  href,
  children,
}) => {
  const { push } = useRouter();

  const isExternalLink = !href?.startsWith('/');
  const Icon = isExternalLink ? ArrowTopRightOnSquareIcon : ChevronRightIcon;

  const onLink = useCallback(() => {
    if (isExternalLink) {
      window.open(href, '_blank');
    } else {
      push(href);
    }
  }, [isExternalLink, push, href]);

  return (
    <div
      className={cn(
        'flex flex-row items-center justify-center cursor-pointer space-x-2',
        className,
      )}
      onClick={onLink}
    >
      <Typography>{children}</Typography>
      <Icon className="w-4 h-4" />
    </div>
  );
};
